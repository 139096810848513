<template>
  <div class="blog">
    <section class="hero-blog" v-if="!this.empty" :style="{ 'background-image': 'url(' + heroImage + ')' }">
      <div class="hero-mask"></div>
      <div
        class="container"
        v-for="item in this.mainPost"
        :value="item.id"
        :key="item.id"
      >
        <h1>{{ item.attributes.title }}</h1>
        <p>{{ item.attributes.description }}</p>
        <router-link
          :to="{
            name: 'BlogPost',
            params: { slug: item.attributes.slug }
          }"
          class="button-overlay"
        >
          Ler mais <img alt="Icon arrow left" src="~@/assets/icons/arrow-left.svg">
        </router-link>
      </div>
    </section>
    <section class="blog-search" v-if="!this.empty">
      <div class="container">
        <div class="search">
          <img src="~@/assets/icons/icon-search.svg" alt="">
          <input type="text" placeholder="Buscar notícias" v-model="searchText" @change="searchChange()" />
          <a @click="searchChange()" class="button-search"><img alt="Icon arrow left" src="~@/assets/icons/arrow-left.svg"></a>
        </div>
      </div>
    </section>
    <section class="news" v-if="!this.empty">
      <div class="container">
        <h2>Notícias</h2>
      </div>
      <div class="container container-news">
        <div
          class="box-news"
          v-for="item in this.lastPosts"
          :value="item.id"
          :key="item.id"
        >
          <img class="news-image" alt="" :src="item.attributes.banner_url">
          <h3>{{ item.attributes.title }}</h3>
          <p>{{ item.attributes.description }}</p>
          <router-link
            :to="{
              name: 'BlogPost',
              params: { slug: item.attributes.slug }
            }"
          >
            Ler mais
          </router-link>
        </div>
      </div>
    </section>
    <div v-if="this.empty" class="empty">
      <img src="~@/assets/image-empty.svg" alt="">
      <h3>Oops!</h3>
      <p> Ainda não temos nenhuma postagem, em breve teremos muito conteúdo.</p>
    </div>
    <section class="other-news" v-if="!this.empty">
      <div class="container">
        <h2>Outras Notícias</h2>
      </div>
      <div class="container container-news">
        <div
          class="box-news"
          v-for="item in this.othersPosts"
          :value="item.id"
          :key="item.id"
        >
          <img class="news-image" alt="" :src="item.attributes.banner_url">
          <div class="news-content">
            <h3>{{ item.attributes.title }}</h3>
            <p>{{ item.attributes.description }}</p>
            <router-link
              :to="{
                name: 'BlogPost',
                params: { slug: item.attributes.slug }
              }"
            >
              Ler mais
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <div class="view-more" v-if="totalItems < 6 || toggleButtonMore">
      <a @click="viewMore()">Carregar mais</a>
    </div>
  </div>
</template>

<script>
import { blogService } from '@/services/blog.service'
// import _ from 'lodash'

export default {
  name: 'Blog',
  metaInfo: {
    title: 'imoGo - Blog'
  },
  data () {
    return {
      empty: false,
      mainPost: {},
      heroImage: '',
      lastPosts: [],
      searchText: '',
      othersPosts: [],
      currentPage: 1,
      perPage: 5,
      totalItems: 0,
      totalPages: 0,
      toggleButtonMore: true
    }
  },

  mounted () {
    this.getPosts()
  },

  methods: {
    async getPosts () {
      try {
        const pagination = {
          perPage: this.perPage,
          page: this.currentPage
        }

        const posts = await blogService.listPosts(pagination)
        this.totalItems = posts.data.meta.count
        this.totalPages = posts.data.meta.pages
        this.currentPage = posts.data.meta.page
        this.empty = (posts.data.data.count === 0)
        this.mainPost = posts.data.data.slice(0, 1)
        this.heroImage = posts.data.data[0].attributes.banner_url
        this.lastPosts = posts.data.data.slice(1, 4)
        this.othersPosts = posts.data.data.slice(4)
      } catch (error) {
        console.log('Ocorreu um erro inesperado.')
      }
    },

    searchChange () {
      this.$router.push(`/blog/pesquisar/${this.searchText}`)
    },

    viewMore () {
      if (this.currentPage < this.totalPages) {
        this.perPage = this.perPage + 5
        const pagination = {
          perPage: this.perPage,
          page: 1
        }
        this.getPosts(pagination)
      }
      if (this.perPage >= this.totalItems) {
        this.toggleButtonMore = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .blog {
    padding: 0 0 100px;
    margin-top: 64px;

    .container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      align-items: flex-start;

      &.container-news {
        max-width: 1200px;
        margin-top: 40px;
      }
    }

    .view-more {
      margin: 60px 0 0;
      text-align: center;

      a {
        outline: none;
        text-decoration: none;
        font-weight: var(--weight-bold);
        color: var(--green-custom);
        cursor: pointer;
      }
    }

    h2 {
      font-weight: 300;
      font-size: 32px;
      line-height: 48px;
      color: #353A50;
    }

    .news {
      padding: 48px 0 98px;
    }

    .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 300px;
      margin: 100px 0;
      text-align: center;
      padding-top: 100px;

      p {
        max-width: 310px;
        margin: 0 auto;
      }

      img {

        margin-bottom: 29px;
      }
    }

    .search {
      margin: 116px auto 72px;
      background: var(--white);
      border: 1px solid var(--gainsboro);
      max-width: 640px;
      width: 100%;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 0 23px;
      height: 69px;
      position: relative;

      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }

      input {
        height: 24px;
        border: none;
        background: none;
        box-shadow: none;
        font-size: var(--size-button);
        font-weight: var(--weight-light);
        color: var(--grey-dark-custom);
        width: 100%;
        max-width: 540px;
        padding-right: 30px;
      }
      .button-search {
        width: 50px;
        position: absolute;
        right: 0px;
        min-width: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          margin: 0 0 0 0px;
        }
      }
    }

    .box-news{
      max-width: 380px;
      width: 100%;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 19px;
        border-radius: 30px;
        height: 254px;
        object-fit: cover;
      }

      h3 {
        margin-bottom: 12px;
        font-weight: 500;
        font-size: var(--size-regular);
        line-height: 29px;
        color: #414F54;
        min-height: 65px;
      }

      p {
        font-weight: 300;
        font-size: var(--size-base);
        line-height: 24px;
        color: #757680;
        margin-bottom: 8px;
        min-height: 70px;
      }

      a {
        text-decoration: none;
        color: var(--maya-blue);
        font-weight: 700;
        font-size: 14px;

        &:hover,
        &:focus,
        &:active {
          color: var(--green-custom);
          animation: all 200ms ease-in-out;
        }
      }
    }

    .other-news {
      padding: 48px 0;

      .container-news {
        flex-direction: column;
      }

      .box-news {
        max-width: 100%;
        padding: 24px 24px 24px 48px;
        border: 2px solid var(--grey-custom);
        border-radius: 40px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        img {
          max-width: 302px;
          margin: 0;
          height: 200px;
        }

        .news-content {
          max-width: 750px;
        }
      }
    }

    .hero-blog {
      min-height: 700px;
      display: flex;
      background-size: cover;
      background-position: center;
      position: relative;
      border-radius: 25px;
      max-width: 1200px;
      margin: 0 auto;
      .hero-mask {
        position: absolute;
        border-radius: 25px;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,0.1);
      }

      .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        max-width: 1020px;
        position: relative;

        h1 {
          max-width: 619px;
          color: var(--white);
          font-weight: 500;
          font-size: 48px;
          line-height: 62px;
          padding: 0 0 24px;
          font-family: var(--font-primary);
        }

        p {
          font-size: var(--size-large);
          max-width: 600px;
          font-size: 20px;
          line-height: 29px;
          font-weight: 300;
          color: var(--white);
          margin: 0 0 24px;
        }

        .button-overlay {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 300;
          color: var(--green-custom);
          font-size: var(--size-button);
          text-decoration: none;
          max-width: 142px;
          border: solid 1px var(--gainsboro);
          min-width: auto;
          padding: 0 0;
          width: 100%;
          height: 51px;
          border-radius: 12px;
          box-sizing: border-box;

          img {
            margin-left: 16px;
          }

          &:hover,
          &:focus,
          &:active {
            color: var(--maya-blue);
            animation: all 200ms ease-in-out;
            background: var(--white);
          }
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0 0 20px;
      margin-top: 0;

      .hero-blog {
        height: 100vh;
        min-height: initial;

        .container {
          h1 {
            font-size: 42px;
            line-height: 52px;
            margin: 0 0 16px;
          }
          p {
            font-size: 16px;
          }
        }
      }

      .news {
        padding: 40px 0;

        .container {
          align-items: flex-start;
          flex-direction: column;

          &.container-news {
            margin-top: 32px;
          }
        }

        .box-news {
          margin-bottom: 32px;

          &:last-child {
            margin-bottom: 0px;
          }

          p {
            margin-bottom: 16px;
          }
        }
      }

      .other-news {
        padding: 0 0 40px;

        .container {
          &.container-news {
            margin-top: 32px;
          }
        }

        .box-news {
          flex-direction: column;
          border-radius: 10px;
          padding: 16px;

          img {
            margin: 0 0 16px;
          }
        }
      }
    }
  }
</style>
