var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog"},[(!this.empty)?_c('section',{staticClass:"hero-blog",style:({ 'background-image': 'url(' + _vm.heroImage + ')' })},[_c('div',{staticClass:"hero-mask"}),_vm._l((this.mainPost),function(item){return _c('div',{key:item.id,staticClass:"container",attrs:{"value":item.id}},[_c('h1',[_vm._v(_vm._s(item.attributes.title))]),_c('p',[_vm._v(_vm._s(item.attributes.description))]),_c('router-link',{staticClass:"button-overlay",attrs:{"to":{
          name: 'BlogPost',
          params: { slug: item.attributes.slug }
        }}},[_vm._v(" Ler mais "),_c('img',{attrs:{"alt":"Icon arrow left","src":require("@/assets/icons/arrow-left.svg")}})])],1)})],2):_vm._e(),(!this.empty)?_c('section',{staticClass:"blog-search"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"search"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-search.svg"),"alt":""}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],attrs:{"type":"text","placeholder":"Buscar notícias"},domProps:{"value":(_vm.searchText)},on:{"change":function($event){return _vm.searchChange()},"input":function($event){if($event.target.composing)return;_vm.searchText=$event.target.value}}}),_c('a',{staticClass:"button-search",on:{"click":function($event){return _vm.searchChange()}}},[_c('img',{attrs:{"alt":"Icon arrow left","src":require("@/assets/icons/arrow-left.svg")}})])])])]):_vm._e(),(!this.empty)?_c('section',{staticClass:"news"},[_vm._m(0),_c('div',{staticClass:"container container-news"},_vm._l((this.lastPosts),function(item){return _c('div',{key:item.id,staticClass:"box-news",attrs:{"value":item.id}},[_c('img',{staticClass:"news-image",attrs:{"alt":"","src":item.attributes.banner_url}}),_c('h3',[_vm._v(_vm._s(item.attributes.title))]),_c('p',[_vm._v(_vm._s(item.attributes.description))]),_c('router-link',{attrs:{"to":{
            name: 'BlogPost',
            params: { slug: item.attributes.slug }
          }}},[_vm._v(" Ler mais ")])],1)}),0)]):_vm._e(),(this.empty)?_c('div',{staticClass:"empty"},[_c('img',{attrs:{"src":require("@/assets/image-empty.svg"),"alt":""}}),_c('h3',[_vm._v("Oops!")]),_c('p',[_vm._v(" Ainda não temos nenhuma postagem, em breve teremos muito conteúdo.")])]):_vm._e(),(!this.empty)?_c('section',{staticClass:"other-news"},[_vm._m(1),_c('div',{staticClass:"container container-news"},_vm._l((this.othersPosts),function(item){return _c('div',{key:item.id,staticClass:"box-news",attrs:{"value":item.id}},[_c('img',{staticClass:"news-image",attrs:{"alt":"","src":item.attributes.banner_url}}),_c('div',{staticClass:"news-content"},[_c('h3',[_vm._v(_vm._s(item.attributes.title))]),_c('p',[_vm._v(_vm._s(item.attributes.description))]),_c('router-link',{attrs:{"to":{
              name: 'BlogPost',
              params: { slug: item.attributes.slug }
            }}},[_vm._v(" Ler mais ")])],1)])}),0)]):_vm._e(),(_vm.totalItems < 6 || _vm.toggleButtonMore)?_c('div',{staticClass:"view-more"},[_c('a',{on:{"click":function($event){return _vm.viewMore()}}},[_vm._v("Carregar mais")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',[_vm._v("Notícias")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',[_vm._v("Outras Notícias")])])
}]

export { render, staticRenderFns }